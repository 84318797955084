/* eslint-disable no-useless-escape */
import { CookieObject } from "./CookieModel";
import json from "../configs/config.json";
export class CookiesService {
    public cookieObj = new CookieObject();

    /**
     * *********************************************************************************************************************
     * Check if application is running in browser environment.
     * *********************************************************************************************************************
     */
    public get isBrowser() {
        return typeof window !== 'undefined';
    }

    /**
     * *********************************************************************************************************************
     * Initialize browser cookies for development environment.
     * *********************************************************************************************************************
     */
    public init() {
        if (process.env.NODE_ENV === "development" && process.title === "browser") {
            if (json.length > 0) {
                this.initBrowserCookies(json);
            } else {
                throw new Error("Config file is empty");
            }
        }
    }

    /**
     * *********************************************************************************************************************
     * Get existing cookie value.
     *
     * @param name  - Cookie name string
     * @returns     - Cookie string
     * *********************************************************************************************************************
     */
    public get(name: string): string {
        const cookieName = `${name}=`;
        const splitResult = document.cookie.split(';');
        let result = "";

        splitResult.forEach(item => {
            const trimmed = item.trim();

            if (trimmed.indexOf(cookieName) === 0) {
                result = decodeURIComponent(trimmed.substring(cookieName.length, trimmed.length));

                // if (result.substring(0, 1) === '{') {
                //     try {
                //         result = JSON.parse(result);
                //         result = result.value || "";
                //     } catch (e) {
                //         return;
                //     }
                // }

                // if (result === 'undefined') {
                //     result = "";
                // }
            }
        });
        return result;
    }

    /**
     * *********************************************************************************************************************
     * Initialize cookies in browser.
     *
     * @param cookiesArr - Array of cookies objects
     * *********************************************************************************************************************
     */
    public initBrowserCookies(cookiesArr: CookieObject[]): void {
        cookiesArr.forEach(item => {
            this.set(item.name, decodeURIComponent(item.value), item.expirationDate, item.path, process.env.DOMAIN_ADDRESS, item.secure, item.sameSite);
        });
    }

    /**
    * *********************************************************************************************************************
    * Set new cookie.
    *
    * @param name       - Cookie name string
    * @param value      - Cookie value string
    * @param days       - Dta in seconds
    * @param path       - Path string
    * @param domain     - Domain name string
    * @param secure     - attribute boolean
    * @param sameSite   - attribute string
    * *********************************************************************************************************************
    */
    public set(name?: string, value?: string, days?: number, path?: string, domain?: string, secure?: boolean, sameSite?: string): void {
        const date = new Date(1970, 0, 1);
        let expires = '';
        let valueToUse = '';
        let secureFlag = '';
        let domainFlag = '';
        let sameSiteFlag = '';

        if (days) {
            date.setSeconds(days);
            expires = `; expires=${date.toUTCString()}`;
            expires = `; expires=${date.toString()}`;
        }

        valueToUse = encodeURIComponent(value as string);

        if (sameSite) {
            sameSiteFlag = `; SameSite=${sameSite}`;

            if (sameSite.toLowerCase() === 'none') {
                secure = true;
            }
        }

        if (secure) {
            secureFlag = '; secure';
        }

        if (domain) {
            domainFlag = `; domain=${encodeURIComponent(domain)}`;
        }

        const cookieName = (name as string).trim().replace(/\s+/g, '-').replace(/[^a-z0-9_-]/gi, '');

        if (cookieName) {
            document.cookie = `${cookieName}=${valueToUse}${expires}; path=${path}${secureFlag}${domainFlag}${sameSiteFlag}`;
        } else {
            throw new Error("Invalid cookie name, only alphanumeric values, '-' and '_' are accepted");
        }
    }

    /**
     * *********************************************************************************************************************
     * Delete cookie if exist.
     *
     * @param name    - Cookie name string
     * @param path    - Path string
     * @param domain  - Domain name string
     * @returns       - True if cookie was removed
     * *********************************************************************************************************************
     */
    public remove(name: string, path?: string, domain?: string): boolean {
        if (!name || !this.check(name)) {
            return false;
        }

        document.cookie = encodeURIComponent(name) +
            "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" +
            (domain ? "; domain=" + domain : "") +
            (path ? "; path=" + path : "; path=/" + "; SameSite=Lax");
        return true;
    }

    /**
     * *********************************************************************************************************************
     * Check if cookie exist.
     *
     * @param name  - Cookie name string
     * @returns     - True if cookie with this name is exist
     * *********************************************************************************************************************
     */
    check(name: string): boolean {
        return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(name).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
    }
}
