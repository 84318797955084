import Vue from "vue";
import { colors } from "vuetify/lib";
import Vuetify from "vuetify/lib/framework";
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.cyan.darken1,
        secondary: colors.grey.lighten4,
        accent: colors.blue.darken2,
        error: colors.red.darken2,
        info: colors.lightBlue,
        success: colors.teal.darken2,
        warning: colors.orange.darken2,
      },
      dark: {
        primary: colors.yellow,
        secondary: colors.yellow,
        accent: colors.yellow,
        error: colors.yellow,
        info: colors.yellow,
        success: colors.yellow,
        warning: colors.yellow,
      },
    },
  },
});
