export class CookieObject {
    /**
     * Cookie name string
     */
    public name: string;
    /**
     * Cookie value string
     */
    public value: string;
    /**
     * Date in seconds
     */
    public expirationDate: number;
    /**
     * Domain name string
     */
    public domain: string;
    /**
     * HostOnly attribute
     */
    public hostOnly: boolean;
    /**
     * HttpOnly attribute
     */
    public httpOnly: boolean;
    /**
     * Path string
     */
    public path: string;
    /**
     * SameSite attribute
     */
    public sameSite: string;
    /**
     * Secure attribute
     */
    public secure: boolean;
    /**
     * Session attribute
     */
    public session: boolean;

    /**
     * CTOR
     * @param domain - Parameter to set domain
     * @param expirationDate - Parameter to set expirationDate
     * @param hostOnly - Parameter to set hostOnly
     * @param httpOnly - Parameter to set httpOnly
     * @param name - Parameter to set name
     * @param path - Parameter to set path
     * @param sameSite - Parameter to set sameSite
     * @param secure - Parameter to set secure
     * @param session - Parameter to set session
     * @param value - Parameter to set value
     */
    public constructor(domain?: string, expirationDate?: number, hostOnly?: boolean, httpOnly?: boolean,
        name?: string, path?: string, sameSite?: string, secure?: boolean, session?: boolean,
        value?: string) {
        this.domain = domain || "";
        this.expirationDate = expirationDate || 0;
        this.hostOnly = hostOnly || false;
        this.httpOnly = httpOnly || false;
        this.name = name || "";
        this.path = path || "; path =/";
        this.sameSite = sameSite || '; SameSite=Lax';
        this.secure = secure || false;
        this.session = session || false;
        this.value = value || "";
    }
}




