
import { Component, Vue, Watch } from "vue-property-decorator";
import { AuthenticationClient } from "npm-auth-client";
import { Values } from "data-collection-base";
import QrcodeVue from "qrcode.vue";
import Authenticator from "authenticator";
import {
    AuthenticationChallenge,
    AuthenticationResponse,
    EAuthenticationState,
    EAuthenticationType,
} from "npm-types";
import libphonenumber from "google-libphonenumber";
// import PNF = require('google-libphonenumber').PhoneNumberFormat;

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
const PNF = libphonenumber.PhoneNumberFormat;

@Component({
    components: {
        QrcodeVue,
    },
})

// *************************************************************** */
// HOME view page
// *************************************************************** */
export default class Login extends Vue {
    /** application title */
    public title: string = "APPLICATION TITLE";

    /** class name */
    public name: string = "Login";

    public isTryAgain: boolean = false;

    /** cluster authentiation accessKey */
    public clusterAccessKey: string = null;

    /** cluster authentiation secretKey */
    public clusterSecretKey: string = null;

    /** cluster authentication backend URL */
    public url: string = null;

    /** error message */
    public isValid: boolean = true;
    public isUsernameValid: boolean = true;

    /** authentication client */
    public authenticationClient: AuthenticationClient = null;

    /** error message */
    public error: string = null;

    /** auth info message */
    public info: string = null;

    /** error indication flag */
    public get hasError(): boolean {
        return this.error != null;
    }

    /** error indication flag */
    public get hasInfo(): boolean {
        return this.info != null;
    }

    /** transaction in progress */
    public transaction: boolean = false;

    /** authentication progress number */
    public progress: number = -1;

    /** username */
    public username: string = "";

    public overlay: boolean = false;
    public absolute: boolean = true;
    public dialogUser: boolean = false;

    /** secret / password / OTP */
    public secret: string = null;
    public phoneNumber: string = null;

    /** challenge type i.e. secret type */
    public secretType: EAuthenticationType = EAuthenticationType.INIT;

    /** secret prompt string */
    public secretPrompt: string = null;

    /** first activation message */
    public activationPrompt: Map<EAuthenticationType, string> = new Map<
        EAuthenticationType,
        string
    >();

    /** UI input bound boolean flag indicating is user wants to see secret field contents */
    public showSecret: boolean = false;

    /** show QR code instead */
    public qrSecret: boolean = true;

    /** activation code from server to display QR code */
    public qrSetupCode: string = "1234 1234 1234 1234 1234 2341 1234";

    /** drop additional output on the console or not */
    public verbose: boolean = false;

    /** Show license expired error flag */
    public showLicExpiredWarning: boolean = false;

    /** Show license expired error flag */
    public showLicRestrictedWarning: boolean = false;

    /** Show license remain days warning flag */
    public showLicRemainWarning: boolean = false;

    /** License remain days number */
    public licRemainDays: number;

    /** Dynamic font size value for username */
    public fontSize: string = "1.25em !important";

    /** Username value for display purposes */
    public usernameDisplay: string = "";

    /** default 'secret' visibility status based on secret type */
    public get secretTypeDefaultVisibility(): boolean {
        let showType;
        switch (this.secretType) {
            case EAuthenticationType.RSA_MGR_RADIUS:
            case EAuthenticationType.RSA_MGR_REST:
            case EAuthenticationType.EMAIL:
            case EAuthenticationType.SMS:
            case EAuthenticationType.AUTHENTICATOR:
            case EAuthenticationType.RSA_CLOUD:
                showType = true;
                break;
            case EAuthenticationType.PASSWORD:
            case EAuthenticationType.RADIUS:
            case EAuthenticationType.LDAP_AD:
            case EAuthenticationType.LDAP:
            case EAuthenticationType.AZURE_AD:
                showType = false;
                break;
            default:
                showType = false;
                break;
        }
        return showType;
    }

    /** list of authentication methods already passed */
    public passed: AuthenticationChallenge[] = [];

    get passedRsaAuth() {
        const rsa = this.passed.filter(x => x.type === EAuthenticationType.RSA_MGR_REST)[0];
        if (rsa) {
            return rsa.state === EAuthenticationState.AuthenticationPassed;
        }
    }

    /** list of authentication methods returned from a server */
    public auth: AuthenticationChallenge[] = [];

    /** default input validator */
    public usernameRules = [(v: string) => !!v || "Please enter your username"];
    public secretRules = [(v: string) => !!v || "This item is required"];

    /** On Key Press to disable error message */
    public onKeyUp(): void {
        if (this.hasError && this.username.length > 0) {
            this.error = null;
        }
    }

    /** returns first character of the username */
    public usernameFirstCharacter(): string {
        let result = "";
        if (this.username.length > 0) {
            result = this.username.substr(0, 1).toUpperCase();
        }
        return result;
    }

    /** Edit username */
    public updateUsername(): void {
        if (this.authenticationClient !== null) {
            this.authenticationClient.disconnect();
        }
        this.progress = -1;
        this.username = "";
        this.overlay = false;
        this.transaction = false;
        this.authenticationClient = null;
        this.secret = null;
        this.phoneNumber = null;
        this.isTryAgain = false;
        this.error = null;
        this.info = null;
        this.dialogUser = false;
        this.secretType = EAuthenticationType.INIT;
        this.secretPrompt = null;
        this.activationPrompt = new Map<EAuthenticationType, string>();
        this.showSecret = false;
        this.qrSecret = true;
        this.passed = [];
        this.auth = [];
        this.isValid = true;
        window.setTimeout(() => {
            document.getElementById("usrnameInput").focus();
        }, 1);
    }

    @Watch("progress")
    public secretStuff() {
        if (this.progress >= 0) {
            const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
                if (document.getElementById("secretInput")) {
                    (
                        this.$refs.secretForm as Vue & {
                            resetValidation: () => void;
                        }
                    ).resetValidation();
                    document.getElementById("secretInput").focus();
                }
            }, 10);
        }
    }

    @Watch("secret")
    public resetValidation() {
        if (this.secret === null) {
            const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
                (
                    this.$refs.secretForm as Vue & {
                        resetValidation: () => void;
                    }
                ).resetValidation();
                document.getElementById("secretInput").focus();
            }, 10);
        }
    }

    @Watch("username")
    public resetUsernameValidation() {
        if (this.username === null || this.username === "") {
            const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
                (
                    this.$refs.usernameForm as Vue & {
                        resetValidation: () => void;
                    }
                ).resetValidation();
                document.getElementById("usrnameInput").focus();
            }, 5);
            this.isValid = false;
        } else {
            this.isValid = true;
        }
    }

    @Watch("username")
    public resizetoFit() {
        this.usernameDisplay = this.username;
        if (this.username !== null || this.username !== "") {
            if (this.username.length >= 15 && this.username.length < 18) {
                this.fontSize = "0.8em !important";
            } else if (this.username.length >= 18 && this.username.length < 20) {
                this.fontSize = "0.7em !important";
            } else if (this.username.length >= 20) {
                this.fontSize = "0.7em !important";
                this.usernameDisplay = this.usernameDisplay.substring(0, 20);
                this.usernameDisplay = this.usernameDisplay + "...";
            } else {
                this.fontSize = "1.1em !important";
            }
        }
    }

    public computeRules(whichOne: string) {
        const rules = [];
        if (whichOne === "phone") {
            rules.push((v: any) => !!v || "Phone number is required");
            rules.push(this.phoneRulesValidator);
        } else if (whichOne === "email") {
            rules.push((v: any) => !!v || "Email address is required");
            rules.push(this.emailValidator);
        }
        return rules;
    }

    public emailValidator(val: string) {
        const emailRegex =
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        if (emailRegex.test(val)) {
            return true;
        } else {
            return "Email address is invalid";
        }
    }

    public phoneRulesValidator(val: string) {
        if (val !== null) {
            try {
                // let number: PhoneNumber = null;
                const number = phoneUtil.parse(val, "");
                const isValid = phoneUtil.isValidNumber(number);
                console.log(phoneUtil.format(number, PNF.INTERNATIONAL));
                this.phoneNumber = phoneUtil.format(number, PNF.INTERNATIONAL);
                if (isValid) {
                    this.secret = phoneUtil.format(
                        number,
                        libphonenumber.PhoneNumberFormat.E164
                    );
                    return true;
                } else {
                    return "Invalid phone number format: please use +[country code][area code][local phone number]";
                }
            } catch (e) {
                return "This phone number is invalid";
            }
        } else {
            return "Phone number is required";
        }
    }

    /** LOGIN PROCEDURE */
    public login(): void {
        // if (document.getElementById("secretInput")) {
        //   (this.$refs.secretForm as Vue & { validate: () => boolean }).validate();
        // }
        if (this.username.length === 0) {
            this.error = "Username can't be blank";
        } else if (
            document.getElementById("secretInput") &&
            (this.secret === null || this.secret.length === 0)
        ) {
            this.error = "Password can't be blank";
            (this.$refs.secretForm as Vue & {
                validate: () => boolean;
            }).validate();
        } else {
            if (this.authenticationClient == null) {
                if (this.verbose) {
                    console.log(
                        "Prepare connection to " +
                        (process.env.BASE_URL ?? "") +
                        (this.url ?? "")
                    );
                }
                this.authenticationClient = new AuthenticationClient();
                this.authenticationClient.connect(
                    (process.env.BASE_URL ?? "") + (this.url ?? ""),
                    this.clusterAccessKey,
                    this.clusterSecretKey
                );
            }

            this.transaction = true;
            if (this.verbose) {
                console.log(
                    "[authenticate] => ",
                    this.username ?? "<empty>",
                    this.secret ?? "<empty>"
                );
            }
            // authentication call
            this.authenticationClient.authenticate(
                this.username,
                this.secret ?? "",
                this.secretType,
                (error: Values | unknown, result: AuthenticationResponse) => {
                    this.showSecret = false;
                    this.qrSecret = false;
                    const isError =
                        error != null ||
                        (result != null &&
                            result.result === EAuthenticationState.AuthenticationFailed);
                    if (this.verbose) {
                        console.log("[ERROR][RESULT] ", error, result, isError);
                    }
                    if (isError) {
                        if (result && result.challenges.filter(chlng => chlng.type === EAuthenticationType.RSA_MGR_REST)[0]?.message.includes('RSA')) {
                            const message = result.challenges.filter(chlng => chlng.type === EAuthenticationType.RSA_MGR_REST)[0].message;
                            this.info = message;
                            this.secretPrompt = message;
                        } else {
                            this.error = "Authentication failed";
                        }
                        this.transaction = false;
                        if (
                            error != null &&
                            (error as string === EAuthenticationState.ErrorLicenseExpired as string)
                        ) {
                            this.showLicExpiredWarning = true;
                        }
                        if (
                            error != null &&
                            (error as string === EAuthenticationState.ErrorLicenseRestriction as string)
                        ) {
                            this.showLicRestrictedWarning = true;
                        }
                        // reset password input
                        this.secret = null;
                        return;
                    }
                    if (result.result === undefined) {
                        this.error = "Connection failed";
                        this.isTryAgain = true;
                        result = null;
                        return;
                    }
                    if (result) {
                        // process challenges
                        let idx = -1;
                        for (const item of result.challenges as AuthenticationChallenge[]) {
                            idx++;
                            if (this.verbose) {
                                console.log(
                                    `[challenge received] => '${idx}' '${item.type}' '${item.state}' '${item.message}'`
                                );
                            }
                            if (item.state !== EAuthenticationState.AuthenticationPassed) {
                                this.secretType = item.type;
                                if (
                                    this.secretPrompt == null ||
                                    item.state === EAuthenticationState.ActivationPending
                                ) {
                                    this.secretPrompt = item.message;

                                    // chack activation prompts
                                    if (item.state === EAuthenticationState.ActivationPending) {
                                        if (this.activationPrompt.has(item.type)) {
                                            if (
                                                this.activationPrompt.get(item.type) ===
                                                this.secretPrompt ||
                                                (this.secretPrompt.indexOf("Please pair") === 0 &&
                                                    item.type === EAuthenticationType.AUTHENTICATOR)
                                            ) {
                                                this.error = "Activation failed, try again";
                                            }
                                        } else {
                                            this.activationPrompt.set(item.type, this.secretPrompt);
                                        }
                                    }
                                    // QR code handling
                                    if (
                                        item.type === EAuthenticationType.AUTHENTICATOR &&
                                        (item.message as string).indexOf("Please pair") === 0
                                    ) {
                                        const message = item.message as string;
                                        const pos = message.indexOf(": '") + 3;
                                        this.qrSecret = true;
                                        this.qrSetupCode = message.substr(pos, 39);
                                        this.qrSetupCode = Authenticator.generateTotpUri(
                                            this.qrSetupCode,
                                            this.username,
                                            "CSP CNA",
                                            "SHA1",
                                            6,
                                            30
                                        );
                                        if (this.verbose) {
                                            console.log(
                                                `Extracted QR code: ${this.qrSetupCode} length: ${this.qrSetupCode.length}`
                                            );
                                        }
                                    }
                                } else {
                                    if (item.state !== EAuthenticationState.AuthenticationPending) {
                                        this.error = item.message;
                                        this.info = null;
                                    } else if (item.state === EAuthenticationState.AuthenticationPending) {
                                        this.info = item.message;
                                        if (item.message.includes('Authentication pending') && item.type === EAuthenticationType.RSA_MGR_REST) {
                                            this.info = this.info + ". Please enter RSA SecurID token: ";
                                            this.secretPrompt = "Please enter RSA SecurID token:";
                                        } else {
                                            this.secretPrompt = item.message;
                                        }
                                    } else if (item.state === EAuthenticationState.AuthenticationPassed) {
                                        this.info = null;
                                    }
                                }
                                break;
                            } else {
                                if (this.passed.length - 1 < idx) {
                                    this.secretPrompt = null;
                                    this.passed.push(item);
                                    if (this.verbose) {
                                        console.log(
                                            `[challenge passed] => '${idx}' '${item.type}' '${item.state}' '${item.message}'`
                                        );
                                    }
                                }
                            }
                        }
                        // increment progress in case there is remaining challenge
                        if (this.progress < idx) {
                            this.progress = idx;
                        }
                        this.secret = null;
                        this.phoneNumber = null;
                        this.isValid = true;
                        this.showSecret = this.secretTypeDefaultVisibility;

                        // final result switch and default actions
                        switch (result.result) {
                            case EAuthenticationState.AuthenticationSessionPassed: {
                                setTimeout(() => {
                                    window.location.href = "/";
                                }, 750);
                                break;
                            }

                            case EAuthenticationState.AuthenticationSessionFailed:
                            case EAuthenticationState.ErrorSessionNotFound: {
                                this.error = "Authentication session aborted";
                                this.isTryAgain = true;
                                break;
                            }

                            case EAuthenticationState.ActivationPending: {
                                if (this.qrSecret) {
                                    this.secret = "yes";
                                }
                                this.transaction = false;
                                break;
                            }

                            default:
                                this.transaction = false;
                                break;
                        }
                    }
                }
            );
        }
    }

    /** License countdown days request */
    public async countdown(): Promise<void> {
        if (this.authenticationClient == null) {
            this.authenticationClient = new AuthenticationClient();
            this.authenticationClient.connect(
                (process.env.BASE_URL ?? "") + (this.url ?? ""),
                this.clusterAccessKey,
                this.clusterSecretKey
            );
            try {
                const countdown = await this.authenticationClient.countdown();
                if (countdown.result <= 14) {
                    this.showLicRemainWarning = true;
                    this.licRemainDays = countdown.result;
                }
            } catch (err) {
                console.log("COUNTDOWN REQUEST FAILED", (err as Error).message);
            }
        }
        await this.authenticationClient.disconnect();
    }

    /** before mount event */
    public beforeMount(): void {
        this.verbose = atob(this.$cookies.get("verbose")) === "true";
        this.title =
            this.$cookies.get("applicationTitle") != null &&
                this.$cookies.get("applicationTitle") !== ""
                ? atob(this.$cookies.get("applicationTitle"))
                : "APPLICATION TITLE";
        this.clusterAccessKey =
            this.$cookies.get("clusterAccessKey") != null &&
                this.$cookies.get("clusterAccessKey") !== ""
                ? atob(this.$cookies.get("clusterAccessKey"))
                : "cluster-default";
        this.clusterSecretKey =
            this.$cookies.get("clusterSecretKey") != null &&
                this.$cookies.get("clusterSecretKey") !== ""
                ? atob(this.$cookies.get("clusterSecretKey"))
                : "Notbopdyman(ochDot1jeodNelRawkaghovDiproij&OntawpEv7QuecIldajnal";
        this.url =
            this.$cookies.get("backendUrl") != null &&
                this.$cookies.get("backendUrl") !== ""
                ? atob(this.$cookies.get("backendUrl"))
                : "/backend/apps/authenticator";

        if (this.verbose) {
            console.log("cluster accessKey => ", this.clusterAccessKey);
            console.log("cluster secretKey => ", this.clusterSecretKey);
        }
        this.countdown();
    }

    /** mounted event */
    public mounted(): void {
        window.setTimeout(() => {
            document.getElementById("usrnameInput").focus();
        }, 50);
    }
}
