
import Vue from "vue";
import { CookiesService } from "./utils/CookiesService";
export default Vue.extend({
  name: "App",
  components: {
    // HelloWorld,
  },
  data: () => ({
    version: ""
  }),
  /** GA get cookie method */
  methods: {},

  /** before mount event */
  beforeMount(): void {
    const $cookies = new CookiesService();
    $cookies.init();

    document.title =
      this.$cookies.get("applicationName") != null &&
        this.$cookies.get("applicationName") !== ""
        ? atob(this.$cookies.get("applicationName"))
        : "Authenticator +";
    this.version = this.$cookies.get("version") != null &&
      this.$cookies.get("version") !== ""
      ? atob(this.$cookies.get("version"))
      : "0.0.0";
  },
});
